import React from 'react';
import { selectCreativeConfig } from '../../ducks/creative';
import { connect } from 'react-redux';
import { ReactComponent as IconLock } from '../../assets/icons/lock.svg';
import useWindowSize from '../../modules/hooks/use-window-size';
import styles from './phone-frame.module.scss';

const OUTER_RADIUS = 48;
const NOTCH_WIDTH = 0.5;
const NOTCH_HEIGHT = 24;

export function getPhoneDimensions(viewportSize, noChrome) {
  // Borders dont change
  const leftButtonWidth = noChrome ? 0 : 4;
  const rightButtonWidth = noChrome ? 0 : 4;
  const topChromeHeight = noChrome ? 0 : 80;
  const bottomChromeHeight = noChrome ? 0 : 70;
  const borderWidth = noChrome ? 0 : 19;

  const borderRect = {
    x: leftButtonWidth,
    y: 0,
    width: viewportSize.width + borderWidth * 2,
    height: viewportSize.height + topChromeHeight + bottomChromeHeight + borderWidth * 2,
  };

  const screenRect = {
    x: borderRect.x + borderWidth,
    y: borderRect.y + borderWidth,
    width: viewportSize.width,
    height: viewportSize.height + topChromeHeight + bottomChromeHeight,
  };

  const viewportRect = {
    x: screenRect.x,
    y: screenRect.y + topChromeHeight,
    width: viewportSize.width,
    height: viewportSize.height,
  };

  const totalWidth = borderRect.width + leftButtonWidth + rightButtonWidth;
  const totalHeight = borderRect.height;

  return {
    borderWidth,
    borderRect,
    viewportRect,
    screenRect,
    totalWidth,
    totalHeight,
    leftButtonWidth,
    rightButtonWidth,
    topChromeHeight,
    bottomChromeHeight,
  };
}

function PhoneFrame(props) {
  const { viewportSize, creativeConfig, renderViewport, renderScreen, noChrome } = props;

  const dimensions = React.useMemo(() => {
    return getPhoneDimensions(viewportSize, noChrome);
  }, [viewportSize, noChrome]);

  const {
    screenRect,
    viewportRect,
    topChromeHeight,
    bottomChromeHeight,
    totalWidth,
    totalHeight,
  } = dimensions;

  const viewportContent = renderViewport && renderViewport();
  const screenContent = renderScreen && renderScreen();

  // Calculate scale factor based on window size.
  // Could probably be some css magic but i'm not that smart
  const windowSize = useWindowSize();
  // inner height - bottom bar - top selector - padding
  const phoneAreaHeight = windowSize.height - 112 * 2 - 64;
  // 62.5% of screen - info box - padding
  const phoneAreaWidth = 0.625 * windowSize.width - 222 * 2 - 64;
  const verticalScaleFactor = phoneAreaHeight / totalHeight;
  const horizontalScaleFactor = phoneAreaWidth / totalWidth;

  // Take the smallest scale, and make sure we don't scale higher than 1 and no lower than 0.5
  let scaleFactor = noChrome
    ? 1
    : Math.max(Math.min(Math.min(verticalScaleFactor, horizontalScaleFactor), 1), 0.5);

  return (
    <div
      className={styles.phoneFrame}
      data-nochrome={Boolean(noChrome)}
      style={{
        width: totalWidth,
        height: totalHeight,
        transform: `scale(${scaleFactor})`,
      }}
    >
      <PhoneFrameSvg dimensions={dimensions} colour={creativeConfig.colour} />
      <div
        className={styles.topChrome}
        style={{
          left: screenRect.x,
          top: screenRect.y,
          height: topChromeHeight,
          width: screenRect.width,
        }}
      >
        <div className={styles.address}>
          <IconLock />
          <span>dailyplay.xyz</span>
        </div>
      </div>
      <div
        className={styles.bottomChrome}
        style={{
          left: screenRect.x,
          top: viewportRect.y + viewportRect.height,
          height: bottomChromeHeight,
          width: screenRect.width,
        }}
      />
      <div
        className={styles.viewportFrame}
        style={{
          left: viewportRect.x,
          top: viewportRect.y,
          width: viewportRect.width,
          height: viewportRect.height,
        }}
      >
        {viewportContent}
      </div>
      <div
        className={styles.screenFrame}
        style={{
          left: screenRect.x,
          top: screenRect.y,
          width: screenRect.width,
          height: screenRect.height,
        }}
      >
        {screenContent}
      </div>
    </div>
  );
}

function PhoneFrameSvg(props) {
  const { dimensions, colour } = props;
  const {
    totalWidth,
    totalHeight,
    borderRect,
    viewportRect,
    screenRect,
    borderWidth,
    leftButtonWidth,
    rightButtonWidth,
  } = dimensions;

  const borderRectProps = {
    className: styles.bodyRect,
    x: borderRect.x,
    y: borderRect.y,
    width: borderRect.width,
    height: borderRect.height,
    rx: OUTER_RADIUS,
    mask: 'url(#screen-mask)',
  };

  const notchWidth = NOTCH_WIDTH * screenRect.width;

  return (
    <svg
      className={styles.svg}
      width={totalWidth}
      height={totalHeight}
      style={{
        width: totalWidth,
        height: totalHeight,
      }}
    >
      <defs>
        <mask id="screen-mask">
          <rect x={0} y={0} width="100%" height="100%" fill="white" />
          <rect
            fill="black"
            x={screenRect.x}
            y={screenRect.y}
            width={screenRect.width}
            height={screenRect.height}
            rx={OUTER_RADIUS - borderWidth}
          />
          <rect
            x={screenRect.x + screenRect.width / 2 - notchWidth / 2}
            y={0}
            width={notchWidth}
            height={screenRect.y + NOTCH_HEIGHT}
            fill="white"
            rx={20}
          />
        </mask>
      </defs>
      <g>
        <rect
          {...borderRectProps}
          style={{
            fill: colour,
          }}
        />
        <rect {...borderRectProps} />
      </g>
      <g style={{ transform: `translateY(${viewportRect.y + 16}px)` }}>
        <rect x={0} y={0} width={leftButtonWidth} height={25} className={styles.button} />
        <rect x={0} y={40} width={leftButtonWidth} height={60} className={styles.button} />
        <rect x={0} y={120} width={leftButtonWidth} height={60} className={styles.button} />
      </g>

      <g
        style={{
          transform: `translate(${borderRect.width + borderRect.x}px, ${viewportRect.y + 16}px)`,
        }}
      >
        <rect x={0} y={70} width={rightButtonWidth} height={90} className={styles.button} />
      </g>

      <g
        style={{
          transform: `translate(${borderRect.x + borderRect.width / 2 - 60 / 2}px, ${
            borderWidth + 6
          }px)`,
          fill: colour,
        }}
      >
        <rect x={0} y={0} height={6} width={60} rx={3} />
        <circle cx={72} r={3} cy={2} />
      </g>
    </svg>
  );
}

const mapStateToProps = (state) => ({
  creativeConfig: selectCreativeConfig(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneFrame);
