import React from "react";
import styles from "./bounce.module.scss";
export default function BounceLoader() {
    return (
        <div className={styles.loaderIcon}>
            <div className={styles.loaderBounce1} />
            <div className={styles.loaderBounce2} />
        </div>
    );
}
