export const leftPad = (str, len = 2, char = "0") => {
    let ret = String(str);
    while (ret.length < len) {
        ret = char + ret;
    }
    return ret;
};

export const getFormatPercent = (digits = 2) => number =>
    isNaN(number) || number === null ? number : (number * 100).toFixed(digits) + "%";
export const getFormatWithSuffix = (suffix, digits = 2) => number =>
    isNaN(number) || number === null ? number : number.toFixed(digits) + suffix;

export const formatSeconds = (num, digits) => getFormatWithSuffix("s", digits)(num);
export const formatTimeOfDay = date => `${leftPad(date.getHours())}:${leftPad(date.getMinutes())}`;
export const formatDate = date =>
    `${leftPad(date.getDate())}/${leftPad(date.getMonth() + 1)}/${date.getYear() + 1900}`;
export const formatDuration = duration => {
    const minutes = Math.floor(duration / 60);
    const seconds = (duration % 60).toFixed(0);
    return leftPad(minutes) + ":" + leftPad(seconds);
};
