import React, { Component } from "react";
import { connect } from "react-redux";
import { loadCreative, selectCreativeConfig } from "../../ducks/creative";
import Phone from "../../components/phone/phone";

import styles from "./single-aggregate-app.module.scss";
import { setView } from "../../ducks/app";
import { incrementPlayCount } from "../../ducks/current-session";
import {
    setPlaybackPosition,
    selectPlaybackPosition,
    selectPlaybackLength,
} from "../../ducks/timeline";

class SingleAggregateApp extends Component {
    animationFrame = null;
    lastFrame = null;

    state = {
        frameReady: false,
    };

    componentDidMount() {
        const { creativeId } = this.props.match.params;
        this.props.setView("aggregate");
        this.props.loadCreative(creativeId);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.creativeConfig !== this.props.creativeConfig ||
            prevProps.playbackLength !== this.props.playbackLength ||
            prevState.frameReady !== this.state.frameReady
        ) {
            if (this.props.creativeConfig.aggregate.timeline && this.state.frameReady) {
                this.startAnimation();
            }
        }
    }

    // TODO: This is duplicating a bit of code in "playback-controls" but I had to do this quickly
    // as a freebie.
    startAnimation() {
        if (this.animationFrame) {
            window.cancelAnimationFrame(this.animationFrame);
        }
        this.lastFrame = performance.now();
        this.animationFrame = requestAnimationFrame(this.animationFrameCallback);
    }

    cancelAnimation() {
        if (this.animationFrame) {
            window.cancelAnimationFrame(this.animationFrame);
            this.animationFrame = null;
        }
    }

    animationFrameCallback = now => {
        let dt = Math.max(0, now - this.lastFrame) / 1000;
        this.lastFrame = now;
        const {
            playbackPosition,
            playbackLength,
            setPlaybackPosition,
            incrementPlayCount,
        } = this.props;

        const newTime = Math.min(playbackPosition + dt, playbackLength);
        setPlaybackPosition(newTime);

        if (newTime === playbackLength) {
            incrementPlayCount();
            // setPlaybackPosition(0);
        } else {
            this.animationFrame = requestAnimationFrame(this.animationFrameCallback);
        }
    };

    render() {
        return (
            <div className={styles.panelContainer}>
                <Phone noChrome onFrameReady={() => this.setState({ frameReady: true })} />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    playbackPosition: selectPlaybackPosition(state),
    playbackLength: selectPlaybackLength(state),
    creativeConfig: selectCreativeConfig(state),
});
const mapDispatchToProps = {
    loadCreative,
    setView,
    incrementPlayCount,
    setPlaybackPosition,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SingleAggregateApp);
