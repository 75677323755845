import React, { Component } from "react";
import { connect } from "react-redux";
import {
    selectCurrentSession,
    incrementPlayCount,
    selectCurrentSessionReady,
    selectCurrentSessionPlayCount,
    selectCurrentSessionPlayWhenReady,
    setSessionPlayWhenReady,
    selectCurrentSessionLoading,
} from "../../ducks/current-session";
import {
    setPlaying,
    setPlaybackPosition,
    selectPlaying,
    selectPlaybackPosition,
    selectPlaybackLength,
} from "../../ducks/timeline";
import { ReactComponent as Hump } from "../../assets/hump.svg";
import { ReactComponent as Play } from "../../assets/icons/play.svg";
import { ReactComponent as Pause } from "../../assets/icons/pause.svg";
import { ReactComponent as Undo } from "../../assets/icons/fa-undo-solid.svg";
import BounceLoader from "../loader/bounce";

import styles from "./playback-controls.module.scss";
import PlaybackControlsSlider from "./playback-controls.slider";
import { selectCreativeConfig } from "../../ducks/creative";
import { selectView } from "../../ducks/app";

class PlaybackControls extends Component {
    animationFrame = null;
    lastFrame = null;

    render() {
        const { creativeConfig, currentSession, currentSessionLoading, view } = this.props;

        // Do not show if we dont need timeline, or havent got our creative config yet.
        if (!creativeConfig[view] || !creativeConfig[view].timeline) return null;

        // If we're a session and we are missing our session + not loading, dont show. this means we have no selection
        if (view === "session" && (!currentSession && !currentSessionLoading)) return null;

        return (
            <div className={styles.playbackControls}>
                <Hump className={styles.hump} />
                {this.renderButton()}
                <PlaybackControlsSlider />
            </div>
        );
    }

    renderButton() {
        const {
            playing,
            setPlaying,
            creativeConfig,
            view,
            incrementPlayCount,
            setPlaybackPosition,
            playbackPosition,
            playbackLength,
            currentSessionReady,
            setSessionPlayWhenReady,
            currentSession,
        } = this.props;
        const scrubbing = creativeConfig[view] && creativeConfig[view].scrubbing;

        const resetIcon = <Undo style={{ width: 20 }} />;

        let icon = <Play />;
        if (playing) {
            if (scrubbing) {
                icon = <Pause />;
            } else {
                icon = resetIcon;
            }
        } else {
            if (playbackPosition === playbackLength) {
                icon = resetIcon;
            } else if (view !== "aggregate" && (!currentSessionReady || !currentSession)) {
                icon = <BounceLoader />;
            }
        }

        const handler = scrubbing
            ? () => setPlaying(!playing)
            : () => {
                  if (playing) {
                      // TODO: this doesnt work for aggregates, but there is probably no need yet?
                      setPlaying(false);
                      incrementPlayCount();
                      setPlaybackPosition(0);
                      setSessionPlayWhenReady(true);
                  } else {
                      setPlaying(true);
                  }
              };

        return (
            <button
                className={styles.playPauseButton}
                onClick={handler}
                disabled={!currentSessionReady}
            >
                {icon}
            </button>
        );
    }

    handleSpacebarPress = e => {
        if (e.repeat) return;
        const {
            playbackLength,
            playing,
            setPlaying,
            creativeConfig,
            view,
            currentSessionReady,
        } = this.props;
        // Don't let no-scrubs pause by pressing space.
        if (creativeConfig[view] && !creativeConfig[view].scrubbing && playing) return;
        if (!currentSessionReady) return;
        if (playbackLength && e.keyCode === 32) {
            setPlaying(!playing);
        }
    };

    componentDidMount() {
        window.addEventListener("keydown", this.handleSpacebarPress);
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleSpacebarPress);
        this.cancelAnimation();
    }

    componentDidUpdate(prevProps) {
        const scrubbing =
            this.props.creativeConfig[this.props.view] &&
            this.props.creativeConfig[this.props.view].scrubbing;

        if (prevProps.currentSession !== this.props.currentSession) {
            this.cancelAnimation();
        }
        if (prevProps.playing && !this.props.playing) {
            this.cancelAnimation();
        }
        if (!prevProps.playing && this.props.playing) {
            this.startAnimation();
        }

        const readyToAutoPlay =
            (this.props.view === "session" &&
                !prevProps.currentSessionReady &&
                this.props.currentSessionReady &&
                this.props.currentSession) ||
            (this.props.view === "session" &&
                !prevProps.currentSession &&
                this.props.currentSession &&
                this.props.selectCurrentSessionReady);

        if (readyToAutoPlay && this.props.currentSessionPlayWhenReady) {
            this.props.setPlaying(true);

            // Only do this once
            this.props.setSessionPlayWhenReady(false);
        }
    }

    animationFrameCallback = now => {
        let dt = Math.max(0, now - this.lastFrame) / 1000;
        this.lastFrame = now;
        const {
            playing,
            setPlaying,
            playbackPosition,
            playbackLength,
            setPlaybackPosition,
            incrementPlayCount,
        } = this.props;

        const scrubbing =
            this.props.creativeConfig[this.props.view] &&
            this.props.creativeConfig[this.props.view].scrubbing;

        const newTime = Math.min(playbackPosition + dt, playbackLength);
        setPlaybackPosition(newTime);

        if (newTime === playbackLength) {
            setPlaying(false);
            incrementPlayCount();
            if (!scrubbing) {
                setPlaybackPosition(0);
            }
            this.animationFrame = null;
        } else if (playing && this.animationFrame) {
            this.animationFrame = requestAnimationFrame(this.animationFrameCallback);
        }
    };

    startAnimation() {
        if (!this.animationFrame) {
            this.lastFrame = performance.now();
            this.animationFrame = requestAnimationFrame(this.animationFrameCallback);
        }
    }

    cancelAnimation() {
        if (this.animationFrame) {
            window.cancelAnimationFrame(this.animationFrame);
            this.animationFrame = null;
        }
    }
}

const mapStateToProps = state => ({
    currentSession: selectCurrentSession(state),
    playing: selectPlaying(state),
    playbackPosition: selectPlaybackPosition(state),
    playbackLength: selectPlaybackLength(state),
    creativeConfig: selectCreativeConfig(state),
    view: selectView(state),
    currentSessionReady: selectCurrentSessionReady(state),
    currentSessionPlayCount: selectCurrentSessionPlayCount(state),
    currentSessionPlayWhenReady: selectCurrentSessionPlayWhenReady(state),
    currentSessionLoading: selectCurrentSessionLoading(state),
});

const mapDispatchToProps = {
    setPlaying,
    setPlaybackPosition,
    incrementPlayCount,
    setSessionPlayWhenReady,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlaybackControls);
