/**
 * Special case for formats where we don't want to monitor all the parts
 *
 * @param {String} format
 * @returns
 */
function getWhitelistedFormatParts(format) {
  var partConfig = {
    'super-skin-v2': {
      parts: ['banner', 'hero'],
    },
  };

  if (partConfig[format]) {
    return partConfig[format];
  }

  return null;
}

function getTopFrame(w, topMost) {
  w = w || window;
  topMost = topMost || window.top;
  try {
    if (topMost.hasAttribute('data-xyz') && topMost.dataset.xyz === 'phone-wrapper') {
      return w.frameElement;
    }
  } catch (error) {
    return w.frameElement;
  }

  if (w === topMost) {
    return null;
  } else if (w.parent !== topMost) {
    return getTopFrame(w.parent, topMost);
  }
  return w.frameElement;
}

function getWindowContext(iframe) {
  var contentWindow = null;

  var innerFrame = iframe.contentWindow.document.querySelector(
    '.xyz-ad-content iframe[name=xyz-ad-iframe]'
  );

  if (innerFrame && innerFrame.contentWindow) {
    // returning force stops this loop and will just return this context.
    return (contentWindow = innerFrame.contentWindow);
  }
  return contentWindow;
}

export const getPolygons = () => {
  const iframe = document.getElementById('creative-preview');
  let polygons = [];
  let contentWindow = getWindowContext(iframe);

  if (!contentWindow || !contentWindow.xyz) {
    return polygons;
  }

  const xyz = contentWindow.xyz;
  const keys = Object.keys(xyz);

  if (xyz && keys.length >= 1) {
    const formatKey = keys.find(function (frameType) {
      return typeof xyz[frameType] === 'function';
    });

    var allParts = null;
    var formatProps = contentWindow[formatKey];
    var whitelistedParts = getWhitelistedFormatParts(formatProps.name);

    if (!whitelistedParts) {
      allParts = Object.keys(formatProps.frames);
    } else {
      allParts = whitelistedParts.parts;
    }
    var topFrames = {};

    Object.keys(formatProps.frames).forEach((k) => {
      topFrames[k] = getTopFrame(formatProps.frames[k].contentWindow);
    });
    if (topFrames) {
      polygons = allParts.map(function (part) {
        if (!topFrames[part]) {
          return {};
        }
        var partResult = topFrames[part].getBoundingClientRect();
        return {
          part,
          x: partResult.x,
          y: partResult.y,
          width: partResult.width,
          height: partResult.height,
        };
      });
    }
  }
  return polygons;
};
