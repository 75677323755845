import { createSelector } from 'reselect';
import api from '../modules/api';
import { creativeConfig } from '../modules/creative-config';
import { loadSession } from './current-session';

const initialState = {
  // Data for the table and vis
  creative: null,
  creativeLoading: true,

  // Data for the aggregate view
  aggregateData: null,
  aggregateLoading: true,
  aggregateScroll: 0,

  // Table state
  searchTerm: '',
  tagFilter: null,
  currentPage: 1,
  pageSize: 10,
  sortColumn: 'attentionTime',
  sortDirection: 'desc',
  aggregatePolygons: null,
};

//
// -----------------
// Action definitions
const REQUEST_CREATIVE = 'playback/creative/REQUEST_CREATIVE';
export const requestCreative = () => ({
  type: REQUEST_CREATIVE,
  payload: {},
});

const SET_CREATIVE = 'playback/creative/SET_CREATIVE';
export const setCreative = (creative) => ({
  type: SET_CREATIVE,
  payload: {
    creative,
  },
});

const REQUEST_AGGREGATE_DATA = 'playback/creative/REQUEST_AGGREGATE_DATA';
export const requestAggregateData = () => ({
  type: REQUEST_AGGREGATE_DATA,
  payload: {},
});

const SET_AGGREGATE_DATA = 'playback/creative/SET_AGGREGATE_DATA';
export const setAggregateData = (aggregateData) => ({
  type: SET_AGGREGATE_DATA,
  payload: {
    aggregateData,
  },
});

const SET_AGGREGATE_SCROLL = 'playback/creative/SET_AGGREGATE_SCROLL';
export const setAggregateScroll = (aggregateScroll) => ({
  type: SET_AGGREGATE_SCROLL,
  payload: {
    aggregateScroll,
  },
});

const SET_SEARCH_TERM = 'playback/creative/SET_SEARCH_TERM';
export const setSearchTerm = (searchTerm) => ({
  type: SET_SEARCH_TERM,
  payload: {
    searchTerm,
  },
});

const SET_TAG_FILTER = 'playback/creative/SET_TAG_FILTER';
export const setTagFilter = (tagFilter) => ({
  type: SET_TAG_FILTER,
  payload: {
    tagFilter,
  },
});

const SET_CURRENT_PAGE = 'playback/creative/SET_CURRENT_PAGE';
export const setCurrentPage = (currentPage) => ({
  type: SET_CURRENT_PAGE,
  payload: {
    currentPage,
  },
});

const SET_SORT_COLUMN = 'playback/creative/SET_SORT_COLUMN';
export const setSortColumn = (sortColumn) => ({
  type: SET_SORT_COLUMN,
  payload: {
    sortColumn,
  },
});

const SET_SORT_DIRECTION = 'playback/creative/SET_SORT_DIRECTION';
export const setSortDirection = (sortDirection) => ({
  type: SET_SORT_DIRECTION,
  payload: {
    sortDirection,
  },
});

const SET_AGGREGATE_POLYGONS = 'playback/creative/SET_AGGREGATE_POLYGONS';
export const setAggregatePolygons = (aggregatePolygons) => ({
  type: SET_AGGREGATE_POLYGONS,
  payload: {
    aggregatePolygons,
  },
});
// -----------------
// Reducer

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CREATIVE: {
      return {
        ...state,
        creativeLoading: true,
      };
    }
    case SET_CREATIVE: {
      return {
        ...state,
        creative: action.payload.creative,
        creativeLoading: false,
      };
    }
    case REQUEST_AGGREGATE_DATA: {
      return {
        ...state,
        aggregateLoading: true,
      };
    }
    case SET_AGGREGATE_DATA: {
      return {
        ...state,
        aggregateData: action.payload.aggregateData,
        aggregateLoading: false,
      };
    }
    case SET_AGGREGATE_SCROLL: {
      return {
        ...state,
        aggregateScroll: action.payload.aggregateScroll,
      };
    }
    case SET_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.payload.searchTerm,
      };
    }
    case SET_TAG_FILTER: {
      return {
        ...state,
        tagFilter: action.payload.tagFilter,
      };
    }
    case SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload.currentPage,
      };
    }
    case SET_SORT_COLUMN: {
      return {
        ...state,
        sortColumn: action.payload.sortColumn,
      };
    }
    case SET_SORT_DIRECTION: {
      return {
        ...state,
        sortDirection: action.payload.sortDirection,
      };
    }
    case SET_AGGREGATE_POLYGONS: {
      return {
        ...state,
        aggregatePolygons: action.payload.aggregatePolygons,
      };
    }
    default:
      return state;
  }
}

//
// -----------------
// Thunks

export function loadCreative(campaignId, initialSession) {
  return async (dispatch) => {
    const creativeRequest = api.get(`/campaign-index/${campaignId}`);
    const aggregateRequest = api.get(`/aggregate/${campaignId}`);

    const creativeResponse = await creativeRequest;
    if (creativeResponse.ok) {
      dispatch(
        setCreative({
          ...creativeResponse.data.content,
          // Hack the campaign name in
          name: creativeResponse.data.Campaign.name,
        })
      );
      if (initialSession) {
        // Load session sets view itself.
        dispatch(
          loadSessionById(campaignId, initialSession.sessionId, creativeResponse.data.sessions)
        );
      }

      const aggregateReponse = await aggregateRequest;

      if (aggregateReponse.ok) {
        dispatch(setAggregateData(aggregateReponse.data.content));
      }
    }
  };
}

// TODO: not sure if this is the best spot to have this
export function loadSessionById(campaignId, sessionId, sessionList) {
  return async (dispatch, getState) => {
    console.log({ sessionId });
    const sessionsToSearch = sessionList || selectSessions(getState());

    const sessionObject = sessionsToSearch.find((d) => d.id === sessionId);
    if (!sessionObject) {
      throw new Error('COULD NOT FIND SESSION', sessionId);
    }
    dispatch(loadSession(campaignId, sessionObject));
  };
}

export function setSearchTermAndResetPage(searchTerm) {
  return async (dispatch, getState) => {
    dispatch(setSearchTerm(searchTerm));
    dispatch(setCurrentPage(1));
  };
}

export function setTagFilterAndResetPage(tagFilter) {
  return async (dispatch, getState) => {
    dispatch(setTagFilter(tagFilter));
    dispatch(setCurrentPage(1));
  };
}

// -----------------
// Selectors

export const selectSessions = (state) =>
  state.creative.creative ? state.creative.creative.sessions : null;
export const selectSortColumn = (state) => state.creative.sortColumn;
export const selectSortDirection = (state) => state.creative.sortDirection;
export const selectSearchTerm = (state) => state.creative.searchTerm;
export const selectTagFilter = (state) => state.creative.tagFilter;
export const selectCurrentPage = (state) => state.creative.currentPage;
export const selectPageSize = (state) => state.creative.pageSize;
export const selectCreativeLoading = (state) => state.creative.creativeLoading;
export const selectCreative = (state) => state.creative.creative;
export const selectAggregateLoading = (state) => state.creative.aggregateLoading;
export const selectAnyLoading = (state) =>
  state.creative.creativeLoading && state.creative.aggregateLoading;
export function selectAggregateScroll(state) {
  return state.creative.aggregateScroll;
}
export function selectAggregateData(state) {
  return state.creative.aggregateData;
}
export function selectAggregatePolygons(state) {
  return state.creative.aggregatePolygons;
}

export function selectCreativeFormat(state) {
  return state.creative.creative.format;
}

export const selectSortedSessions = createSelector(
  selectSessions,
  selectSortColumn,
  selectSortDirection,
  (sessions, sortColumn, sortDirection = 'asc') => {
    if (!sessions) return null;
    if (!sortColumn) return sessions;

    const compFunc =
      sortDirection === 'asc'
        ? (a, b) => a[sortColumn] - b[sortColumn]
        : (a, b) => b[sortColumn] - a[sortColumn];

    return sessions.slice().sort(compFunc);
  }
);

const addIndex = (session, index) => ({
  rank: index + 1,
  ...session,
});
export const selectFilteredSessions = createSelector(
  selectSortedSessions,
  selectSearchTerm,
  selectTagFilter,
  (sortedSessions, searchTerm, tagFilter) => {
    if (!sortedSessions) {
      return null;
    }
    if (!searchTerm && !tagFilter) {
      return sortedSessions.map(addIndex);
    }
    const filtered = sortedSessions.filter((session) => {
      if (searchTerm) {
        // If we have a search term and our row doesnt have the string in the full ID, cya.
        // TODO: other fields?
        if (session.id.includes(searchTerm)) {
          return true;
        }
        if (session.idShort.includes(searchTerm)) {
          return true;
        }

        return false;
      }
      if (tagFilter) {
        // If we have a tag selected and our row doesnt have the tag, goodbye.
        if (!session.tags.includes(tagFilter)) {
          return false;
        }
      }
      return true;
    });

    const withIndices = filtered.map(addIndex);
    return withIndices;
  }
);

export const selectCurrentPageRows = createSelector(
  selectFilteredSessions,
  selectCurrentPage,
  selectPageSize,
  (filteredSessions, currentPage, pageSize) => {
    if (!filteredSessions) return null;
    const start = pageSize * (currentPage - 1);
    const end = start + pageSize;
    return filteredSessions.slice(start, end);
  }
);

export const selectCreativeConfig = createSelector(selectCreative, (creative) => {
  if (!creative) {
    return creativeConfig.standard;
  }
  return creativeConfig[creative.format] || creativeConfig.standard;
});
