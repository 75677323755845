import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  selectCurrentSession,
  selectInterpolatedOffsetSample,
  selectCurrentSessionLoading,
  selectCurrentSessionPlayCount,
  setSessionReady,
  selectCurrentSessionMeta,
  selectCurrentSessionReady,
} from '../../ducks/current-session';
import { selectPlaying, selectPlaybackLength } from '../../ducks/timeline';
import styles from './phone.module.scss';
import {
  selectCreative,
  selectCreativeConfig,
  setAggregateScroll,
  selectAggregateLoading,
  setAggregatePolygons,
  selectAggregateScroll,
} from '../../ducks/creative';
import { selectDebug, selectView } from '../../ducks/app';
import DebugSessionVis from '../visualisations/debug/debug-session.vis';
import Bounce from '../loader/bounce';
import PhoneFrame, { getPhoneDimensions } from './phone-frame';
import { injectTagInpreview } from './helpers/injectTag';
import { getPolygons } from './helpers/ads';
import axiosInstance from '../../modules/api';
class Phone extends Component {
  frameRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = { tagInjected: false, interval: null, fetchTag: false };
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  // TODO: Session view. This one might be needed for the session view (see comment in render funciton tagget wiht 'Session view')
  getSrc(props) {
    const { creative, creativeConfig } = props || this.props;
    if (!creative) return null;
    return `${creative.tag}-${creativeConfig.size}`;
  }
  // TODO: Session view. This one might be needed for the session view (see comment in render funciton tagget wiht 'Session view')
  getIframeKey(props) {
    const frameSrc = this.getSrc(props);
    const { creativeConfig, currentSession, currentSessionPlayCount, view } = props;
    let iframeKey = frameSrc;
    if (creativeConfig[view] && !creativeConfig[view].scrubbing) {
      iframeKey = frameSrc + currentSessionPlayCount;
    }

    if (currentSession) {
      iframeKey += currentSession.id;
    }

    return iframeKey;
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.view === 'session' &&
      this.props.creativeConfig.session.trackScroll &&
      prevProps.interpolatedSample &&
      this.props.interpolatedSample &&
      prevProps.interpolatedSample !== this.props.interpolatedSample
    ) {
      this.setScroll(this.props.interpolatedSample.scroll);
    }

    // Watch for changes that would result in a new iframe key and set frame to not ready.
    if (this.getIframeKey(prevProps) !== this.getIframeKey(this.props)) {
      this.props.setSessionReady(false);
    }

    // ad the tag only once
    if (this.props.creative && !this.state.tagInjected && !this.state.fetchTag) {
      this.setState({ fetchTag: true });
      axiosInstance.get(`/tag/${this.props.creative.tag}`).then((result) => {
        const { data } = result;

        const tagInjected = injectTagInpreview({
          tagSnippet: data.tagSnippet,
          size: this.props.creativeConfig.size,
        });
        if (tagInjected) {
          this.setState({
            tagInjected,
          });
        }
      });
    }
  }

  setScroll(yPos) {
    if (this.frameRef.current) {
      const scrolling = {
        top: yPos,
        left: 0,
      };
      this.frameRef.current.contentWindow.postMessage(scrolling, '*');
    }
  }

  getPhoneSize() {
    const { view, currentSession, noChrome } = this.props;

    // If we have no chrome, we standardise to one size.
    if (noChrome) {
      return {
        screen: { width: 375, height: 812 },
        viewport: { width: 375, height: 654 },
      };
    }
    return view === 'session' && currentSession
      ? currentSession.phoneSize
      : {
          screen: { width: 414, height: 896 },
          viewport: { width: 414, height: 738 },
        };
  }

  handleFrameLoad = () => {
    const {
      view,
      creativeConfig,
      setSessionReady,
      setAggregateScroll,
      aggregateScroll,
    } = this.props;
    setSessionReady(true);

    if (view === 'aggregate' && creativeConfig.aggregate.scroll) {
      setAggregateScroll(0);
    }

    this.frameRef.current.contentWindow.addEventListener('scroll', function (event) {
      setAggregateScroll(this.scrollY);
    });

    const interval = setInterval(() => {
      let polygons = getPolygons();
      const adjustedPolygons = polygons.map((d) => ({
        ...d,
        y: d.y + aggregateScroll,
      }));
      setAggregatePolygons(adjustedPolygons);
    }, 1000);

    this.setState({
      interval,
    });
    this.props.onFrameReady && this.props.onFrameReady();
  };

  renderIframe() {
    const { creativeConfig, view } = this.props;
    const frameSrc = this.getSrc();
    if (!frameSrc) {
      return;
    }

    const phoneSize = this.getPhoneSize();
    const dimensions = getPhoneDimensions(phoneSize.viewport);
    // TODO: Session view.
    // we might need the iframe key for the 'session' view
    // ATM is broken, need to check with Maddie if we need to fix it,
    // or if has to be remove. Leaving the code here for now.
    const iframeKey = this.getIframeKey(this.props);

    return (
      <>
        <iframe
          id="creative-preview"
          title="Creative preview"
          className={styles.iframe}
          data-xyz="phone-wrapper"
          width={dimensions.viewportRect.width}
          height={dimensions.viewportRect.height}
          key={iframeKey}
          src={`/creative-preview.html`}
          ref={this.frameRef}
          scrolling={
            view === 'creative' || (view === 'aggregate' && creativeConfig.aggregate.scroll)
              ? 'yes'
              : 'no'
          }
          onLoad={this.handleFrameLoad}
        />
        {this.renderPhoneLoader()}
      </>
    );
  }

  renderVisualisation() {
    const {
      creativeConfig,
      currentSession,
      showAggregate,
      debug,
      view,
      currentSessionMeta,
    } = this.props;

    const phoneSize = this.getPhoneSize();
    const dimensions = getPhoneDimensions(phoneSize.viewport);

    const VisualisationComponent =
      view === 'session' && currentSessionMeta
        ? creativeConfig.sessionVis
        : view === 'aggregate'
        ? creativeConfig.aggregateVis
        : null;
    if (!VisualisationComponent) {
      return null;
    }
    return (
      <>
        <VisualisationComponent
          phoneSize={phoneSize}
          dimensions={dimensions}
          showAggregate={showAggregate}
          creativeConfig={creativeConfig}
        />
        {debug && currentSession && view === 'session' && (
          <DebugSessionVis dimensions={dimensions} />
        )}
      </>
    );
  }

  renderPhoneLoader() {
    const {
      aggregateLoading,
      view,
      currentSessionMeta,
      currentSession,
      currentSessionReady,
    } = this.props;

    const everythingForSessionReady = currentSessionReady && currentSession;

    const visible =
      (view === 'aggregate' && aggregateLoading) ||
      (view === 'session' && currentSessionMeta && !everythingForSessionReady);

    return (
      <div className={styles.loadingOverlay} data-visible={Boolean(visible)}>
        <Bounce />
      </div>
    );
  }

  render() {
    const { noChrome } = this.props;
    const phoneSize = this.getPhoneSize();
    return (
      <div className={styles.phone} data-nochrome={Boolean(noChrome)}>
        <PhoneFrame
          viewportSize={phoneSize.viewport}
          noChrome={noChrome}
          renderViewport={() => this.renderIframe()}
          renderScreen={() => this.renderVisualisation()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentSession: selectCurrentSession(state),
  view: selectView(state),
  showAggregate: Boolean(!selectCurrentSessionLoading(state)) && selectView(state) === 'aggregate',
  creative: selectCreative(state),
  interpolatedSample: selectInterpolatedOffsetSample(state),
  creativeConfig: selectCreativeConfig(state),
  playing: selectPlaying(state),
  debug: selectDebug(state),
  playbackLength: selectPlaybackLength(state),
  currentSessionPlayCount: selectCurrentSessionPlayCount(state),
  currentSessionMeta: selectCurrentSessionMeta(state),
  aggregateLoading: selectAggregateLoading(state),
  currentSessionReady: selectCurrentSessionReady(state),
  aggregateScroll: selectAggregateScroll(state),
});

const mapDispatchToProps = {
  setAggregateScroll,
  setAggregatePolygons,
  setSessionReady,
};

export default connect(mapStateToProps, mapDispatchToProps)(Phone);
