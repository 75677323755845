/**
 * Inject the creative tag into the correct placeholder
 *
 * @param {Object} params {tagId: string, size: string}
 * @returns {boolean} true if tag is tag is added to the iframe, otherwise returns false
 */
export const injectTagInpreview = (params) => {
  const { tagSnippet, size } = params;
  const iframe = document.getElementById('creative-preview');
  if (needToAddTag(iframe)) {
    let snippet = replaceRef(tagSnippet);
    const div = getPlaceholderDiv(size, iframe);

    if (div) {
      div.innerHTML = snippet;
      return true;
    }
    return false;
  }
};

/**
 * Check that the iframe is there and the ad is not injected yet
 *
 * @param {*} iframe
 * @returns {boolean}
 */
function needToAddTag(iframe) {
  return iframe && !document.getElementsByClassName('xyz-ad-content').length;
}

function getPlaceholderDiv(size, iframe) {
  const placeholderSuffix = size === '300x250' ? 'mrec' : 'banner';
  return iframe.contentWindow.document.getElementById(`ad-placeholder-${placeholderSuffix}`);
}

/**
 * Replace the ref property so that the host-config will think the ad is coming from the dailyplay,
 * this is done to prevent the 'site non certified' error
 *
 * @param {String} tagSnippet
 * @returns the modifed tag snippet
 */
function replaceRef(tagSnippet) {
  // try to pass dailyplay url.
  return tagSnippet.replace(/(ref=)(.*?)(})/, '$1"https://the.dailyplay.xyz?demo-host=xyz"$3');
}
