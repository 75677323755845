import React, { Component } from "react";
import { connect } from "react-redux";

import styles from "./dashboard.module.scss";
import {
    loadCreativesIndex,
    selectCreativesIndex,
    selectCreativesIndexRequesting,
    selectCreativesByClient,
} from "../../ducks/dashboard";
import { Link } from "react-router-dom";
import { ReactComponent as PlaygroundLogo } from "../../assets/icons/logo-inv.svg";

class Dashboard extends Component {
    componentDidMount() {
        const { creativesIndex, creativesIndexRequesting, loadCreativesIndex } = this.props;
        if (!creativesIndexRequesting && !creativesIndex) {
            loadCreativesIndex();
        }
    }

    render() {
        const { creativesByClient } = this.props;
        const clients =
            creativesByClient &&
            creativesByClient.map(client => {
                const tiles = client.Campaigns.map(campaign => {
                    return (
                        <Link key={campaign.id} className={styles.tile} to={`/${campaign.id}`}>
                            <label className={styles.tileLabel}>{campaign.name}</label>
                            <label className={styles.tileFormat}>{campaign.format}</label>
                        </Link>
                    );
                });

                return (
                    <div key={client.id} className={styles.client}>
                        <h2>{client.name}</h2>
                        <div className={styles.clientTiles}>{tiles}</div>
                    </div>
                );
            });

        return (
            <div className={styles.dashboard}>
                <PlaygroundLogo className={styles.logo} />
                <h1>Playback</h1>
                <div className={styles.clientContainer}>{clients}</div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({
    creativesIndex: selectCreativesIndex(state),
    creativesByClient: selectCreativesByClient(state),
    creativesIndexRequesting: selectCreativesIndexRequesting(state),
});
const mapDispatchToProps = {
    loadCreativesIndex,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);
