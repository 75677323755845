import React from "react";
import debounce from "lodash.debounce";

function useDimensions(wrapperRef, extraDependencies = []) {
    const [dimensions, setDimensions] = React.useState();

    React.useEffect(() => {
        const resizeCallback = () => {
            if (!wrapperRef || !wrapperRef.current) return;
            const boundingClientRect = wrapperRef.current.getBoundingClientRect();
            if (!boundingClientRect) return;
            setDimensions(boundingClientRect);
        };
        resizeCallback();
        const handleResize = debounce(resizeCallback, 100);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [wrapperRef, ...extraDependencies]);

    return {
        dimensions,
        setDimensions,
    };
}

export default useDimensions;
