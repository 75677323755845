import { combineReducers } from "redux";

import app from "./app";
import creative from "./creative";
import currentSession from "./current-session";
import timeline from "./timeline";
import dashboard from "./dashboard";

export default combineReducers({
    app,
    creative,
    currentSession,
    timeline,
    dashboard,
});
