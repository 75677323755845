import React from "react";
import { createSelector } from "reselect";
import { connect } from "react-redux";
import { scaleLinear } from "d3";
import styles from "./playback-controls.slider.module.scss";
import useDimensions from "../../modules/hooks/use-dimensions";
import { selectCurrentSession } from "../../ducks/current-session";
import {
    selectPlaying,
    selectPlaybackPosition,
    selectPlaybackLength,
    setPlaybackPosition,
    setPlaying,
} from "../../ducks/timeline";
import { formatDuration } from "../../modules/format";
import useWindowEventListener from "../../modules/hooks/use-window-event-listener";
import { selectView } from "../../ducks/app";
import { selectAggregateData, selectCreativeConfig } from "../../ducks/creative";

function getScales(props, dimensions) {
    if (!dimensions) return null;

    const xScale = scaleLinear()
        .rangeRound([0, dimensions.width])
        .domain([0, props.playbackLength])
        .clamp(true);

    const colourDomain = props.aggregateTimelineData ? props.aggregateTimelineData.max : 1;
    const colourScale = scaleLinear()
        .range(["white", "#e91e63"])
        .domain([0, colourDomain]);

    return {
        x: xScale,
        colour: colourScale,
        dimensions,
    };
}

const visibleSegHeight = 12;
function renderSessionVis(props, scales) {
    const { currentSession } = props;
    const adVisibleRects =
        currentSession &&
        currentSession.visibleSegments.map((segment, segmentIndex) => {
            const start = scales.x(segment.startTime);
            const end = scales.x(segment.endTime);
            const width = end - start;
            return (
                <rect
                    key={segmentIndex}
                    className={styles.visibleSegment}
                    x={start}
                    width={width}
                    y={scales.dimensions.height - visibleSegHeight}
                    height={visibleSegHeight}
                />
            );
        });

    const adAttentionRects =
        currentSession &&
        currentSession.attentionSegments.map((segment, segmentIndex) => {
            const start = scales.x(segment.startTime);
            const end = scales.x(segment.endTime);
            const width = end - start;
            return (
                <rect
                    key={segmentIndex}
                    className={styles.attentionSegment}
                    x={start}
                    width={width}
                    y={0}
                    height={scales.dimensions.height - visibleSegHeight}
                />
            );
        });

    return (
        <g>
            {adVisibleRects}
            {adAttentionRects}
        </g>
    );
}

function renderAggregateVis(props, scales) {
    const { aggregateTimelineData } = props;

    const bins =
        aggregateTimelineData &&
        aggregateTimelineData.bins.map((bin, binIndex) => {
            const start = scales.x(bin.start);
            const end = scales.x(bin.end);
            const width = end - start;
            return (
                <rect
                    key={binIndex}
                    x={start}
                    width={width}
                    y={0}
                    height={scales.dimensions.height}
                    data-value={bin.value}
                    style={{
                        fill: scales.colour(bin.value),
                    }}
                />
            );
        });

    return <g>{bins}</g>;
}

function renderSliderVis(props, scales, dimensions, show) {
    if (!scales || !show) return;

    const { playbackPosition, view } = props;

    const vis =
        view === "session" ? renderSessionVis(props, scales) : renderAggregateVis(props, scales);
    const playbackFill = (
        <rect
            x={0}
            y={0}
            width={scales.x(playbackPosition)}
            height={scales.dimensions.height}
            className={styles.playbackFill}
        />
    );

    return (
        <>
            {playbackFill}
            {vis}
        </>
    );
}

function renderPlaybackPosition(props, scales, dimensions, dragging, setDragging, show) {
    const { playbackPosition, setPlaying, creativeConfig, view } = props;

    const handleMouseMove = e => {
        if (dragging) {
            const x = e.clientX - dimensions.left;
            const playbackTime = scales.x.invert(x);
            props.setPlaybackPosition(playbackTime);
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    useWindowEventListener("mousemove", handleMouseMove);
    useWindowEventListener("mouseup", handleMouseUp);

    if (!dimensions || !scales) return null;
    if (!show) return null;

    const xPosition = scales.x(playbackPosition);

    return (
        <div
            className={styles.handleContainer}
            onMouseDown={() => {
                if (!creativeConfig[view].scrubbing) return;
                setDragging(true);
                setPlaying(false);
            }}
            onClick={e => {
                if (!creativeConfig[view].scrubbing) return;
                const playbackTime = scales.x.invert(e.clientX - dimensions.left);
                props.setPlaybackPosition(playbackTime);
            }}
            data-dragging={dragging}
        >
            <div className={styles.playbackLine} style={{ left: xPosition }} />
            <div className={styles.playbackTime} style={{ left: xPosition }}>
                {formatDuration(playbackPosition)}
            </div>
        </div>
    );
}

function PlaybackControlsSlider(props) {
    const { view, currentSession, aggregateTimelineData } = props;
    const svgRef = React.useRef();
    const { dimensions } = useDimensions(svgRef, [view]);
    const scales = getScales(props, dimensions);
    const [dragging, setDragging] = React.useState(false);

    const showVisuals =
        !(view === "session" && !currentSession) &&
        !(view === "aggregate" && !aggregateTimelineData);

    return (
        <div className={styles.slider}>
            <div className={styles.labels}>
                {view === "session" && (
                    <>
                        <label>Attention</label>
                        <label>Ad Visible</label>
                    </>
                )}
                {view === "aggregate" && <label>Ad Views</label>}
            </div>
            <div className={styles.sliderContainer}>
                <svg className={styles.vis} ref={svgRef}>
                    {renderSliderVis(props, scales, dimensions, showVisuals)}
                </svg>
                {renderPlaybackPosition(
                    props,
                    scales,
                    dimensions,
                    dragging,
                    setDragging,
                    showVisuals
                )}
                <label className={styles.playbackLengthLabel}>
                    {formatDuration(props.playbackLength)}
                </label>
            </div>
        </div>
    );
}

const selectAggregateTimelineData = createSelector(
    selectAggregateData,
    aggregateData => {
        return aggregateData && aggregateData.timelineData;
    }
);

export const mapStateToProps = state => ({
    playing: selectPlaying(state),
    playbackPosition: selectPlaybackPosition(state),
    playbackLength: selectPlaybackLength(state),
    currentSession: selectCurrentSession(state),
    aggregateTimelineData: selectAggregateTimelineData(state),
    view: selectView(state),
    creativeConfig: selectCreativeConfig(state),
});

const mapDispatchToProps = {
    setPlaybackPosition,
    setPlaying,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlaybackControlsSlider);
