import { createSelector } from "reselect";
import { selectAggregateData } from "./creative";
import { selectView } from "./app";
import { selectCurrentSession } from "./current-session";

const viewInitialState = {
    playing: false,
    playbackPosition: 0,
};

const initialState = {
    aggregate: { ...viewInitialState },
    session: { ...viewInitialState },
};

//
// -----------------
// Action definitions
const SET_PLAYING = "playback/timeline/SET_PLAYING";
const setPlayingAction = (view, playing) => ({
    type: SET_PLAYING,
    payload: { view, playing },
});

const SET_PLAYBACK_POSITION = "playback/timeline/SET_PLAYBACK_POSITION";
export const setPlaybackPositionAction = (view, playbackPosition) => ({
    type: SET_PLAYBACK_POSITION,
    payload: { view, playbackPosition },
});

// -----------------
// Reducer

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_PLAYING: {
            return {
                ...state,
                [action.payload.view]: {
                    ...state[action.payload.view],
                    playing: action.payload.playing,
                },
            };
        }
        case SET_PLAYBACK_POSITION: {
            return {
                ...state,
                [action.payload.view]: {
                    ...state[action.payload.view],
                    playbackPosition: action.payload.playbackPosition,
                },
            };
        }
        default:
            return state;
    }
}

//
// -----------------
// Thunks

export function setPlaying(playing) {
    return async (dispatch, getState) => {
        const state = getState();
        const view = selectView(state);
        if (playing) {
            const position = selectPlaybackPosition(state);
            const length = selectPlaybackLength(state);
            if (position >= length) {
                dispatch(setPlaybackPositionAction(view, 0));
            }
        }

        dispatch(setPlayingAction(view, playing));
    };
}

export function setPlaybackPosition(playbackPosition) {
    return async (dispatch, getState) => {
        const state = getState();
        const view = selectView(state);
        dispatch(setPlaybackPositionAction(view, playbackPosition));
    };
}

// -----------------
// Selectors

function selectTimelineStates(state) {
    return state.timeline;
}

export const selectPlaying = createSelector(
    selectView,
    selectTimelineStates,
    (view, timelineStates) => {
        if (view === "creative") return null;
        return timelineStates[view].playing;
    }
);
export const selectPlaybackPosition = createSelector(
    selectView,
    selectTimelineStates,
    (view, timelineStates) => {
        if (view === "creative") return null;
        return timelineStates[view].playbackPosition;
    }
);
export const selectPlaybackLength = createSelector(
    selectView,
    selectCurrentSession,
    selectAggregateData,
    (view, currentSession, aggregateData) => {
        if (view === "session") return currentSession && currentSession.length;
        if (view === "aggregate") return aggregateData && aggregateData.playbackLength;
        if (view === "creative") return null;
    }
);
