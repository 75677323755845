import React, { Component } from "react";
import { createSelector } from "reselect";
import { connect } from "react-redux";
import { selectPlaying, selectPlaybackPosition } from "../../../ducks/timeline";

import styles from "./superskin-aggregate.vis.module.scss";
import { selectAggregateData } from "../../../ducks/creative";
import { selectView } from "../../../ducks/app";

class SuperskinAggregateVis extends Component {
    render() {
        const { dimensions, viewAggregate, showAggregate } = this.props;
        const { screenRect } = dimensions;

        if (!showAggregate) return null;

        return (
            <svg
                className={styles.overlay}
                style={{
                    width: screenRect.width,
                    height: screenRect.height,
                }}
            >
                <defs>
                    <filter x="-50%" y="-50%" width="200%" height="200%" id="ssAggBlur">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="16" />
                    </filter>
                </defs>
                {viewAggregate &&
                    viewAggregate.map((point, index) => (
                        <circle
                            key={index}
                            cx={0}
                            cy={0}
                            r={32}
                            className={styles.aggregatePoint}
                            filter="url(#ssAggBlur)"
                            style={{
                                transform: `translate(${point.x * screenRect.width}px, ${point.y *
                                    screenRect.height}px)`,
                            }}
                        />
                    ))}
            </svg>
        );
    }
}

const selectViewAggregate = createSelector(
    selectAggregateData,
    selectPlaybackPosition,
    (aggregateData, playbackPosition) => {
        if (!aggregateData) return null;
        return aggregateData.sessions
            .map(d =>
                d.find(sample => sample.start <= playbackPosition && sample.end >= playbackPosition)
            )
            .filter(Boolean);
    }
);

const mapStateToProps = state => ({
    playing: selectPlaying(state),
    view: selectView(state),
    viewAggregate: selectViewAggregate(state),
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SuperskinAggregateVis);
