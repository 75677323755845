import React, { Component } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectPlaying } from '../../../ducks/timeline';

import styles from './hitchhike-aggregate.vis.module.scss';
import {
    selectAggregateData,
    selectAggregateScroll,
    selectAggregatePolygons,
} from '../../../ducks/creative';

const LARGE_BANNER_TOP_PLACEHOLDER = 2507;

class HitchhikeAggregateVis extends Component {
    render() {
        const {
            normalizedStep,
            relativeStep,
            showAggregate,
            viewportBannerTop,
            dimensions,
        } = this.props;

        const { screenRect } = dimensions;

        if (!showAggregate) return null;

        const totalSamples =
            ((normalizedStep && normalizedStep.length) || 0) +
            ((relativeStep && relativeStep.length) || 0);

        const dotOpacity = Math.min((1 / totalSamples) * 30, 0.5);

        return (
            <svg
                className={styles.overlay}
                style={{
                    width: screenRect.width,
                    height: screenRect.height,
                }}
            >
                {normalizedStep &&
                    normalizedStep.samples.map((point) => {
                        return (
                            <circle
                                key={point.key}
                                cx={0}
                                cy={0}
                                r={24}
                                data-direction={point.direction}
                                data-type={point.type}
                                data-y={point.y}
                                className={styles.aggregatePoint}
                                style={{
                                    transform: `translate(${point.x * screenRect.width}px, ${
                                        point.y * screenRect.height
                                    }px)`,
                                    opacity: dotOpacity,
                                }}
                            />
                        );
                    })}
                {relativeStep &&
                    relativeStep.samples.map((point) => {
                        return (
                            <circle
                                key={point.key}
                                cx={0}
                                cy={0}
                                r={24}
                                data-direction={point.direction}
                                data-type="r"
                                data-y={point.y}
                                className={styles.aggregatePoint}
                                style={{
                                    transform: `translate(${point.x * screenRect.width}px, ${
                                        viewportBannerTop + point.y
                                    }px)`,
                                    opacity: dotOpacity,
                                }}
                            />
                        );
                    })}
            </svg>
        );
    }
}

const selectLargeBannerDocumentTop = createSelector(
    selectAggregatePolygons,
    (aggregatePolygons) => {
        if (aggregatePolygons) {
            const inlineBanner = aggregatePolygons.find((d) => d.part === 'inlineBanner');
            if (inlineBanner) {
                console.log('using message', inlineBanner.y);
                return inlineBanner.y;
            }
        }
        return LARGE_BANNER_TOP_PLACEHOLDER;
    }
);

const selectViewportBannerTop = createSelector(
    selectLargeBannerDocumentTop,
    selectAggregateScroll,
    (bannerTop, aggregateScroll) => {
        return bannerTop - aggregateScroll;
    }
);

const selectNormalizedViewportBannerTop = createSelector(
    selectLargeBannerDocumentTop,
    selectAggregateScroll,
    (state, props) => props.phoneSize.viewport,
    (bannerTop, aggregateScroll, viewport) => {
        return aggregateScroll / (bannerTop - viewport.height);
    }
);

const selectNormalizedStep = createSelector(
    selectAggregateData,
    selectNormalizedViewportBannerTop,
    (aggregateData, normalizedViewportTop) => {
        if (!aggregateData) return null;

        const step = aggregateData.normalizedSteps.find(
            (d) => normalizedViewportTop >= d.stepStart && normalizedViewportTop < d.stepEnd
        );

        if (!step) return null;
        return step;
    }
);

const selectRelativeStep = createSelector(
    selectAggregateData,
    selectViewportBannerTop,
    (state, props) => props.phoneSize.viewport,
    (aggregateData, viewportBannerTop, viewport) => {
        if (!aggregateData) return null;

        // We pick samples based on _how far the banner is from bottom of view_
        const relativeScrollPos = viewportBannerTop - viewport.height;

        const step = aggregateData.relativeSteps.find(
            (d) => relativeScrollPos >= d.stepStart && relativeScrollPos < d.stepEnd
        );

        if (!step) return null;
        return step;
    }
);

const mapStateToProps = (state, props) => ({
    playing: selectPlaying(state, props),
    normalizedStep: selectNormalizedStep(state, props),
    relativeStep: selectRelativeStep(state, props),
    aggregateScroll: selectAggregateScroll(state, props),
    viewportBannerTop: selectViewportBannerTop(state, props),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HitchhikeAggregateVis);
