import React from "react";
import { connect } from "react-redux";
import {
    selectCurrentSession,
    selectCurrentSample,
    selectElapsedAttentionTime,
} from "../../ducks/current-session";
import { ReactComponent as AttentionIconActive } from "../../assets/icons/attention-time-active.svg";
import styles from "./player-info.module.scss";
import { formatSeconds, formatTimeOfDay, formatDate } from "../../modules/format";
import { selectView, selectDebug } from "../../ducks/app";

function PlayerInfo(props) {
    const { currentSession, currentSample, elapsedAttentionTime, view, debug } = props;

    let sessionDetails;
    if (view === "session" && currentSession) {
        const date = new Date(currentSession.timestamp);
        const metricActive = currentSample && currentSample.start.attention;
        sessionDetails = (
            <>
                <div className={styles.metricBlock} data-active={metricActive}>
                    <AttentionIconActive className={styles.metricIcon} />
                    <div className={styles.metricText}>
                        <div className={styles.metricLabel}>Attention time</div>
                        <div className={styles.metricValue}>
                            <span className={styles.metricValueBig}>
                                {formatSeconds(elapsedAttentionTime)}
                            </span>
                            <span className={styles.metricValueSmall}>
                                / {formatSeconds(currentSession.attentionTime)}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={styles.detailBlock}>
                    <table className={styles.detailTable}>
                        <tbody>
                            <tr>
                                <td>Session ID</td>
                                <td>{currentSession.idShort}</td>
                            </tr>
                            {date && (
                                <tr>
                                    <td>Date</td>
                                    <td>{formatDate(date)}</td>
                                </tr>
                            )}
                            <tr>
                                <td>Time</td>
                                <td>{formatTimeOfDay(date)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    return <div className={styles.playerInfo}>{sessionDetails}</div>;
}

const mapStateToProps = state => ({
    currentSession: selectCurrentSession(state),
    currentSample: selectCurrentSample(state),
    elapsedAttentionTime: selectElapsedAttentionTime(state),
    view: selectView(state),
    debug: selectDebug(state),
});

export default connect(mapStateToProps)(PlayerInfo);
