import api from "../modules/api";
import { createSelector } from "reselect";

const initialState = {
    creativesIndex: null,
    creativesIndexRequesting: false,
    creativesIndexError: null,
};

//
// -----------------
// Action definitions
const REQUEST_CREATIVES_INDEX = "playback/dashboard/REQUEST_CREATIVES_INDEX";
export const requestCreativesIndex = () => ({
    type: REQUEST_CREATIVES_INDEX,
    payload: {},
});

const SUCCESS_CREATIVES_INDEX = "playback/dashboard/SUCCESS_CREATIVES_INDEX";
export const setCreativesIndex = creativesIndex => ({
    type: SUCCESS_CREATIVES_INDEX,
    payload: {
        creativesIndex,
    },
});

const ERROR_CREATIVES_INDEX = "playback/dashboard/ERROR_CREATIVES_INDEX";
export const errorCreativesIndex = error => ({
    type: ERROR_CREATIVES_INDEX,
    payload: { error },
});

// -----------------
// Reducer

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case REQUEST_CREATIVES_INDEX: {
            return {
                ...state,
                creativesIndexRequesting: true,
                creativesIndexError: null,
            };
        }
        case SUCCESS_CREATIVES_INDEX: {
            return {
                ...state,
                creativesIndex: action.payload.creativesIndex,
                creativesIndexRequesting: false,
                creativesIndexError: null,
            };
        }
        case ERROR_CREATIVES_INDEX: {
            return {
                ...state,
                creativesIndex: null,
                creativesIndexRequesting: false,
                creativesIndexError: action.payload.error,
            };
        }
        default:
            return state;
    }
}

//
// -----------------
// Thunks

export function loadCreativesIndex() {
    return async dispatch => {
        try {
            dispatch(requestCreativesIndex());
            const res = await api.get(`/client-index`);
            if (res.ok) {
                dispatch(setCreativesIndex(res.data));
            } else {
                throw new Error(res.data);
            }
        } catch (e) {
            dispatch(errorCreativesIndex(e.toString()));
        }
    };
}

// -----------------
// Selectors

export const selectCreativesIndex = state => state.dashboard.creativesIndex;
export const selectCreativesIndexRequesting = state => state.dashboard.creativesIndexRequesting;
export const selectCreativesIndexError = state => state.dashboard.creativesIndexError;

export const selectCreativesByClient = createSelector(
    selectCreativesIndex,
    clients => {
        if (!clients) return null;
        return clients;
    }
);
