import axios from 'axios';
export const BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
export const AUTH = process.env.REACT_APP_AUTH || 'notset';

// Set config defaults when creating the instance
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  validateStatus(status) {
    return status < 500; // Reject only if the status code is greater than or equal to 500
  },
  headers: { authentication: AUTH },
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Do something with response data
    const { status } = response;
    return { ...response, ok: status >= 200 && status < 300 };
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
