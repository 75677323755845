export const VIEWS = [{ type: 'aggregate', label: 'Aggregate', path: '/' }];

export const VIEWS_MAP = VIEWS.reduce(
  (acc, n) => ({
    ...acc,
    [n.type]: n,
  }),
  {}
);

export default VIEWS;
