import React from "react";
import ReactDOM from "react-dom";
import Root from "./views/root";
import { Provider } from "react-redux";
import store from "./store";

import "./styles/index.scss";

ReactDOM.render(
    <Provider store={store}>
        <Root />
    </Provider>,
    document.getElementById("root")
);
