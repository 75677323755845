import React, { Component } from 'react';
import { connect } from 'react-redux';
import Phone from '../../components/phone/phone';
import PlaybackControls from '../../components/playback-controls/playback-controls';
import PlayerInfo from '../../components/player-info';
import { selectCreativeConfig, selectCreative } from '../../ducks/creative';
import { selectCurrentSession, selectCurrentSessionLoading } from '../../ducks/current-session';
import { VIEWS, VIEWS_MAP } from '../../modules/views';

import styles from './player.module.scss';
import { selectView } from '../../ducks/app';

class Player extends Component {
  renderViewSelect() {
    const { view, creative } = this.props;

    const viewIndex = VIEWS.indexOf(VIEWS_MAP[view]);
    const translate = viewIndex === 0 ? '-60px' : viewIndex === 1 ? '-50%' : 'calc(-100% + 60px)';

    return (
      <div
        className={styles.viewSelector}
        style={{
          transform: `translateX(${translate})`,
        }}
      >
        {creative &&
          VIEWS.map((viewType) => (
            <div
              key={viewType.type}
              className={styles.viewButton}
              data-active={view === viewType.type}
              to={`/${creative.id}${viewType.path}`}
            >
              {viewType.label}
            </div>
          ))}
      </div>
    );
  }

  render() {
    const { creativeConfig } = this.props;
    return (
      <div
        className={styles.playerContainer}
        style={{
          backgroundColor: creativeConfig.colour,
        }}
      >
        <div className={styles.phoneArea}>
          <Phone />
          {/* {this.renderTrackingVisType()} */}
          {this.renderViewSelect()}
        </div>
        <div className={styles.playbackContainer}>
          <PlaybackControls />
        </div>
        <PlayerInfo />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hasSession: Boolean(selectCurrentSession(state)) || selectCurrentSessionLoading(state),
  creativeConfig: selectCreativeConfig(state),
  view: selectView(state),
  creative: selectCreative(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Player);
