import React, { Component } from "react";
import { connect } from "react-redux";
import {
    selectInterpolatedPolygons,
    selectInterpolatedOffsetSample,
} from "../../../ducks/current-session";
import styles from "./debug-session.vis.module.scss";

class DebugSessionVis extends Component {
    render() {
        const { interpolatedSample, interpolatedPolygons } = this.props;
        const { screenRect } = this.props.dimensions;

        const crosshair = interpolatedSample && (
            <>
                <line
                    x1={interpolatedSample.eyeX}
                    x2={interpolatedSample.eyeX}
                    y1={0}
                    y2={screenRect.height}
                    stroke="white"
                    strokeWidth={3}
                />
                <line
                    x1={0}
                    x2={screenRect.width}
                    y1={interpolatedSample.eyeY}
                    y2={interpolatedSample.eyeY}
                    stroke="white"
                    strokeWidth={3}
                />
            </>
        );

        const polygons =
            interpolatedPolygons &&
            interpolatedPolygons.map((polygon, index) => {
                return (
                    <rect key={index} {...polygon} y={polygon.y} className={styles.debugPolygon} />
                );
            });
        return (
            <svg
                className={styles.overlay}
                style={{
                    width: screenRect.width,
                    height: screenRect.height,
                }}
            >
                {crosshair}
                {polygons}
            </svg>
        );
    }
}

const mapStateToProps = state => ({
    interpolatedSample: selectInterpolatedOffsetSample(state),
    interpolatedPolygons: selectInterpolatedPolygons(state),
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DebugSessionVis);
