import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as PlaygroundLogo } from '../../assets/icons/logo-inv.svg';
import { selectCreativeConfig, selectCreative } from '../../ducks/creative';
import styles from './browse.module.scss';
import { setDebug, selectDebug, selectView } from '../../ducks/app';
import { Spacing } from '../../components/spacing/spacing';

class Browse extends Component {
  render() {
    const { creativeConfig, setDebug, debug, creative } = this.props;

    if (!creative) {
      return null;
    }

    return (
      <div className={styles.browseContainer}>
        <div className={styles.headerIcons}>
          <PlaygroundLogo
            onDoubleClick={(e) => {
              if (e.shiftKey) {
                setDebug(!debug);
              }
            }}
          />
          <div>
            <h2>Format</h2>
            <div className={styles.formatContainer}>
              <label>{creativeConfig.name}</label>
              {creativeConfig.iconSrc ? (
                <img
                  src={creativeConfig.iconSrc}
                  alt=""
                  style={{
                    background: creativeConfig.colour,
                    width: '100px',
                  }}
                />
              ) : null}
            </div>
          </div>
          <Spacing />
          <div>
            <h2>Study</h2>
            <label>{creative.name}</label>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  creativeConfig: selectCreativeConfig(state),
  debug: selectDebug(state),
  view: selectView(state),
  creative: selectCreative(state),
});

const mapDispatchToProps = {
  setDebug,
};

export default connect(mapStateToProps, mapDispatchToProps)(Browse);
