import { setPlaying, setPlaybackPosition } from "./timeline";

const initialState = {
    view: "creative",
    dev: process.env.NODE_ENV === "development",
    debug: process.env.NODE_ENV === "development" || window.location.search.includes("debug"),
};

//
// -----------------
// Action definitions

const SET_VIEW = "playback/app/SET_VIEW";
export function setView(view) {
    return async (dispatch, getState) => {
        const state = getState();
        const oldView = selectView(state);
        if (oldView === view) return;

        if (oldView === "session") {
            dispatch(setPlaying(false));
            dispatch(setPlaybackPosition(0));
        }

        dispatch({
            type: SET_VIEW,
            payload: { view },
        });
    };
}

const SET_DEBUG = "playback/app/SET_DEBUG";
export const setDebug = debug => ({
    type: SET_DEBUG,
    payload: { debug },
});

// -----------------
// Reducer

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_VIEW: {
            return {
                ...state,
                view: action.payload.view,
            };
        }
        case SET_DEBUG: {
            return {
                ...state,
                debug: action.payload.debug,
            };
        }
        default:
            return state;
    }
}

//
// -----------------
// Thunks

// -----------------
// Selectors
export const selectView = state => state.app.view;
export const selectDebug = state => state.app.debug;
