import React, { Component } from "react";
import { connect } from "react-redux";
import Browse from "../browse";
import Player from "../player";

import styles from "./app.module.scss";
import { loadCreative, loadSessionById } from "../../ducks/creative";
import { setView } from "../../ducks/app";
import { clearSession } from "../../ducks/current-session";

class App extends Component {
    componentDidMount() {
        this.checkView();
        this.checkCreativeAndSession();
    }

    componentDidUpdate(prevProps) {
        this.checkView(prevProps);
        this.checkCreativeAndSession(prevProps);
    }

    checkCreativeAndSession(prev) {
        const { loadCreative, loadSessionById } = this.props;
        const { creativeId, sessionId } = this.props.match.params;
        if (prev) {
            const { creativeId: oldCreativeId, sessionId: oldSessionId } = prev.match.params;

            if (oldCreativeId !== creativeId) {
                // Probably doesn't happen, but just incase.
                loadCreative(creativeId, sessionId ? { sessionId } : null);
            } else {
                // Havent changed creative, but have changed session.
                // TODO: dedupe this
                if (sessionId !== oldSessionId) {
                    loadSessionById(creativeId, sessionId);
                }
            }
        } else {
            // Mounted. We want to set it.
            loadCreative(creativeId, sessionId ? { sessionId } : null);
        }
    }

    checkView(prev) {
        const { aggregate, sessionList, setView, clearSession } = this.props;
        const { sessionId } = this.props.match.params;

        if (aggregate) {
            setView("aggregate");
        } else if (sessionId || sessionList) {
            // We clear the session if we're going to the session list
            setView("session");
            clearSession();
        } else {
            setView("creative");
        }
    }

    render() {
        return (
            <div className={styles.panelContainer}>
                <Browse history={this.props.history} />
                <Player />
            </div>
        );
    }
}

const mapStateToProps = (state, props) => ({});
const mapDispatchToProps = {
    loadCreative,
    loadSessionById,
    setView,
    clearSession,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
