import SuperskinIcon from '../assets/icons/creatives/super-skin.png';
import HangtimeIcon from '../assets/icons/creatives/hang-time.png';
import HoverboardIcon from '../assets/icons/creatives/hoverboard.png';
import HitchhikeIcon from '../assets/icons/creatives/hitchhike.png';

import HangtimeAggregate from '../components/visualisations/hangtime/hangtime-aggregate.vis';
import SuperskinAggregate from '../components/visualisations/superskin/superskin-aggregate.vis';
import HitchhikeAggregate from '../components/visualisations/hitchhike/hitchhike-aggregate.vis';
// this has to import after an aggregate or literally everything will explode *:shrug:*
import StandardSession from '../components/visualisations/standard/standard-session.vis';

export const creativeConfig = {
  standard: {
    colour: '#CCC',
    name: 'Standard',
    iconSrc: null,
    session: {
      timeline: true,
      trackScroll: true,
      scrubbing: true,
    },
    aggregate: {
      scroll: true,
      timeline: false,
    },
    size: '300x250',
  },
  HANG_TIME: {
    colour: '#FF743F',
    name: 'Hang Time Animated Background',
    iconSrc: HangtimeIcon,
    aggregateVis: HangtimeAggregate,
    sessionVis: StandardSession,
    session: {
      trackScroll: true,
      timeline: true,
      scrubbing: true,
    },
    aggregate: {
      scroll: true,
      timeline: false,
      reloadFrame: false,
      scrubbing: true,
      polygonMessages: false,
    },
    size: '300x250',
  },
  HOVER_BOARD: {
    colour: '#8f33aa',
    name: 'Hoverboard Animated Background',
    iconSrc: HoverboardIcon,
    aggregateVis: HangtimeAggregate,
    sessionVis: StandardSession,
    session: {
      trackScroll: true,
      timeline: true,
      scrubbing: true,
    },
    aggregate: {
      scroll: true,
      timeline: false,
      reloadFrame: false,
      scrubbing: true,
      polygonMessages: false,
    },
    size: '300x250',
  },
  HANG_TIME_JNR: {
    name: 'Hang time jnr Animated Background',
    aggregateVis: HangtimeAggregate,
    sessionVis: StandardSession,
    session: {
      trackScroll: true,
      timeline: true,
      scrubbing: true,
    },
    aggregate: {
      scroll: true,
      timeline: false,
      reloadFrame: false,
      scrubbing: true,
      polygonMessages: false,
    },
    size: '300x250',
  },
  SUPER_SKIN: {
    colour: '#0078D8',
    name: 'Super Skin Animated Background',
    iconSrc: SuperskinIcon,
    sessionVis: StandardSession,
    aggregateVis: SuperskinAggregate,
    session: {
      trackScroll: true,
      scrubbing: false,
      timeline: true,
      torchHeight: 0.0625,
      noRelativeNormalisation: true,
    },
    aggregate: {
      scroll: false,
      timeline: true,
      reloadFrame: true,
      scrubbing: true,
      polygonMessages: false,
    },
    size: '320x50',
  },
  HITCHHIKE: {
    colour: '#f44539',
    name: 'Hitchhike Animated Background',
    iconSrc: HitchhikeIcon,
    aggregateVis: HitchhikeAggregate,
    sessionVis: StandardSession,
    session: {
      trackScroll: true,
      timeline: true,
      scrubbing: true,
    },
    aggregate: {
      scroll: true,
      timeline: false,
      reloadFrame: false,
      scrubbing: true,
      polygonMessages: true,
    },
    size: '320x50',
  },
  // TODO: add remaining formats
};
