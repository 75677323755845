import React from "react";

function useWindowEventListener(event, callback) {
    React.useEffect(() => {
        window.addEventListener(event, callback);
        return () => {
            window.removeEventListener(event, callback);
        }
    }, [event, callback]);
}

export default useWindowEventListener;
