import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import App from './app';
import Dashboard from './dashboard';
import SingleAggregateApp from './single-aggregate-app';

function Root() {
  return (
    <div>
      <Router>
        <div>
          <Route path="/" exact component={Dashboard} />
          <Route
            path="/:creativeId"
            exact
            children={({ match, ...props }) =>
              match ? <App {...props} aggregate={true} match={match} /> : null
            }
          />
          <Route path="/:creativeId/session/:sessionId" exact component={App} />
        </div>
      </Router>
    </div>
  );
}
export default Root;
