import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectInterpolatedOffsetSample } from '../../../ducks/current-session';

import styles from './standard-session.vis.module.scss';

class StandardSessionVis extends Component {
  render() {
    const { dimensions, interpolatedSample, creativeConfig } = this.props;
    const { screenRect } = dimensions;

    const percentHeight = creativeConfig.torchHeight || 0.125;
    const rectHeight = screenRect.height * percentHeight;
    const maskShape = interpolatedSample && (
      <rect
        c={0}
        y={interpolatedSample.eyeY - rectHeight / 2}
        height={rectHeight}
        width={'100%'}
        id="circle"
        fill="black"
        filter="url(#spotlightBlur)"
      />
    );

    return (
      <svg
        className={styles.overlay}
        style={{
          width: screenRect.width,
          height: screenRect.height,
        }}
      >
        <defs>
          <filter x="-50%" y="-50%" width="200%" height="200%" id="spotlightBlur">
            <feGaussianBlur in="SourceGraphic" stdDeviation="20" />
          </filter>
          <mask id="spotlightCutOut">
            <rect x="0" y="0" width="100%" height={screenRect.height} fill="white" />
            {maskShape}
          </mask>
        </defs>
        <rect
          className={styles.spotlightRect}
          x="0"
          y="0"
          width="100%"
          height="100%"
          mask="url(#spotlightCutOut)"
          data-attention={interpolatedSample && interpolatedSample.attention}
        />
      </svg>
    );
  }
}

const mapStateToProps = (state) => ({
  interpolatedSample: selectInterpolatedOffsetSample(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StandardSessionVis);
