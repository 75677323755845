import { createSelector } from "reselect";
import api from "../modules/api";
import { selectAggregatePolygons, selectCreativeConfig } from "./creative";
import { selectPlaybackPosition, setPlaybackPosition, setPlaying } from "./timeline";

const initialState = {
    currentSession: null,
    sessionMeta: null,
    loading: false,
    playCount: 0,
    ready: false,
    playWhenReady: true,
};

//
// -----------------
// Action definitions
const SET_CURRENT_SESSION_LOADING = "playback/currentSession/SET_CURRENT_SESSION_LOADING";
export const setCurrentSessionLoading = loading => ({
    type: SET_CURRENT_SESSION_LOADING,
    payload: { loading },
});

const SET_CURRENT_SESSION_META = "playback/currentSession/SET_CURRENT_SESSION_META";
export const setCurrentSessionMeta = sessionMeta => ({
    type: SET_CURRENT_SESSION_META,
    payload: { sessionMeta },
});

const SET_CURRENT_SESSION = "playback/currentSession/SET_CURRENT_SESSION";
export const setCurrentSession = sessionData => ({
    type: SET_CURRENT_SESSION,
    payload: { sessionData },
});

const CLEAR_SESSION = "playback/currentSession/CLEAR_SESSION";
export const clearSessionData = () => ({
    type: CLEAR_SESSION,
});

const INCREMENT_PLAY_COUNT = "playback/currentSession/INCREMENT_PLAY_COUNT";
export const incrementPlayCount = () => ({
    type: INCREMENT_PLAY_COUNT,
});

const SET_READY = "playback/currentSession/SET_READY";
export const setSessionReady = ready => ({
    type: SET_READY,
    payload: { ready },
});

const SET_PLAY_WHEN_READY = "playback/currentSession/SET_PLAY_WHEN_READY";
export const setSessionPlayWhenReady = playWhenReady => ({
    type: SET_PLAY_WHEN_READY,
    payload: { playWhenReady },
});

// -----------------
// Reducer

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_SESSION_LOADING: {
            return {
                ...state,
                loading: action.payload.loading,
            };
        }
        case SET_CURRENT_SESSION_META: {
            return {
                ...state,
                sessionMeta: action.payload.sessionMeta,
            };
        }
        case SET_CURRENT_SESSION: {
            return {
                ...state,
                currentSession: action.payload.sessionData,
                loading: false,
            };
        }
        case INCREMENT_PLAY_COUNT: {
            return {
                ...state,
                playCount: state.playCount + 1,
            };
        }
        case SET_READY: {
            return {
                ...state,
                ready: action.payload.ready,
            };
        }
        case SET_PLAY_WHEN_READY: {
            return {
                ...state,
                playWhenReady: action.payload.playWhenReady,
            };
        }
        case CLEAR_SESSION: {
            return initialState;
        }
        default:
            return state;
    }
}

//
// -----------------
// Thunks
export function loadSession(campaignId, sessionMeta) {
    const { id } = sessionMeta;
    return async (dispatch, getState) => {
        dispatch(clearSessionData());
        dispatch(setPlaybackPosition(0));
        dispatch(setPlaying(false));
        dispatch(setCurrentSessionLoading(true));
        dispatch(setCurrentSessionMeta(sessionMeta));

        // TODO: what is the PK here.
        const res = await api.get(`/campaign/${campaignId}/session/${id}`);
        if (res.ok) {
            const nowSessionMeta = selectCurrentSessionMeta(getState());
            if (!nowSessionMeta || nowSessionMeta.id === sessionMeta.id) {
                dispatch(setCurrentSession(res.data.content));
                dispatch(setCurrentSessionLoading(false));
            }
        }
    };
}

export function clearSession() {
    return async (dispatch, getState) => {
        dispatch(clearSessionData());
    };
}

// -----------------
// Selectors

export const selectCurrentSession = state => state.currentSession.currentSession;
export const selectCurrentSessionLoading = state => state.currentSession.loading;
export const selectCurrentSessionPlayCount = state => state.currentSession.playCount;
export const selectCurrentSessionReady = state => state.currentSession.ready;
export const selectCurrentSessionPlayWhenReady = state => state.currentSession.playWhenReady;
export const selectCurrentSessionMeta = state => state.currentSession.sessionMeta;

export const selectCurrentSample = createSelector(
    selectCurrentSession,
    (state, props) => selectPlaybackPosition(state, props),
    (currentSession, playbackPosition) => {
        if (!currentSession || playbackPosition === null) return null;
        const { samples } = currentSession;
        const sample = samples.find(
            sample => playbackPosition >= sample.start.time && playbackPosition < sample.end.time
        );
        return sample;
    }
);

// TODO: better place
const lerp = (start, end, pct) => start + (end - start) * pct;
export const selectIntepolatePercentage = createSelector(
    selectCurrentSample,
    (state, props) => selectPlaybackPosition(state, props),
    (currentSample, playbackPosition) => {
        if (!currentSample) return null;
        const sampleLength = currentSample.end.time - currentSample.start.time;
        const secondsIn = playbackPosition - currentSample.start.time;
        return secondsIn / sampleLength;
    }
);
export const selectInterpolatedSample = createSelector(
    selectCurrentSample,
    selectIntepolatePercentage,
    (currentSample, lerpPct) => {
        if (!currentSample) return;
        return Object.keys(currentSample.start).reduce(
            (acc, key) => ({
                ...acc,
                [key]:
                    typeof currentSample.start[key] === "number"
                        ? lerp(currentSample.start[key], currentSample.end[key], lerpPct)
                        : currentSample.start[key],
            }),
            {}
        );
    }
);

export const selectInterpolatedPolygons = createSelector(
    selectCurrentSample,
    selectIntepolatePercentage,
    (currentSample, lerpPct) => {
        if (!currentSample) return;

        const startPolygons = currentSample.start.adPolygons;
        const endPolygons = currentSample.end.adPolygons;

        const numPolys = Math.max(startPolygons.length, endPolygons.length);

        let ret = [];
        for (let i = 0; i < numPolys; i++) {
            const start = startPolygons[i];
            const end = endPolygons[i];
            if (!start) {
                ret.push(end);
            } else if (!end) {
                ret.push(start);
            } else {
                const yOffset = lerp(currentSample.start.scroll, currentSample.end.scroll, lerpPct);
                ret.push({
                    x: lerp(start.x, end.x, lerpPct),
                    y: lerp(start.y, end.y, lerpPct) + yOffset,
                    height: lerp(start.height, end.height, lerpPct),
                    width: lerp(start.width, end.width, lerpPct),
                });
            }
        }

        return ret;
    }
);

export const selectInterpolatedOffsetSample = createSelector(
    state => selectCreativeConfig(state),
    selectInterpolatedSample,
    selectAggregatePolygons,
    (creativeConfig, interpolatedSample, aggPolygons) => {
        if (creativeConfig.session.noRelativeNormalisation) {
            return interpolatedSample;
        }

        if (
            !aggPolygons ||
            !aggPolygons.length ||
            !interpolatedSample ||
            !interpolatedSample.adPolygons.length
        )
            return null;
        const anchorPolygon = aggPolygons[aggPolygons.length - 1];

        const sampleAnchorPoly =
            interpolatedSample.adPolygons[interpolatedSample.adPolygons.length - 1];
        const docY = interpolatedSample.scroll + sampleAnchorPoly.y;

        const newScroll = anchorPolygon.y + (interpolatedSample.scroll - docY);

        // console.log({ scroll: interpolatedSample.scroll, docY, sampleAnchorPoly, anchorPolygon });
        // console.log({ aggPolygons });

        return {
            ...interpolatedSample,
            scroll: newScroll,
        };
    }
);

export const selectElapsedAttentionTime = createSelector(
    selectCurrentSession,
    (state, props) => selectPlaybackPosition(state, props),
    (currentSession, playbackPosition) => {
        if (!currentSession || playbackPosition === null) return null;
        const { attentionSegments } = currentSession;
        const touchedSegments = attentionSegments.filter(
            segment => playbackPosition >= segment.startTime
        );
        const elapsedSegments = touchedSegments.filter(
            segment => segment.endTime < playbackPosition
        );

        const elapsedSegmentAmount = elapsedSegments.reduce(
            (acc, n) => acc + (n.endTime - n.startTime),
            0
        );

        if (touchedSegments.length !== elapsedSegments.length) {
            // We're currently inside a segment.
            const currentSegment = touchedSegments[touchedSegments.length - 1];
            const timeThroughThisSegment = playbackPosition - currentSegment.startTime;
            return elapsedSegmentAmount + timeThroughThisSegment;
        } else {
            return elapsedSegmentAmount;
        }
    }
);
