import React, { Component } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectPlaying } from '../../../ducks/timeline';

import styles from './hangtime-aggregate.vis.module.scss';
import {
  selectAggregateData,
  selectAggregateScroll,
  selectCreativeFormat,
} from '../../../ducks/creative';

// This is the original message from henry -> TODO: get messages to calculate this on the fly.
// We use the same file for different formats, however the offset for the hangtime jnr is different form the others.
// This helper function maps the offset according to the creative format.
// I don't know how the original value was calculated (it might be the size of the height of the creative + the top div in the phone frame + the bottom div in the phone frame)
const getCreativeOffset = (format) => {
  switch (format) {
    case 'HANG_TIME_JNR':
      return 575;
    default:
      return 844.5;
  }
};

class HangtimeAggregateVis extends Component {
  render() {
    const { dimensions, viewAggregate, showAggregate } = this.props;
    const { screenRect } = dimensions;
    if (!showAggregate) {
      return null;
    }

    const dotOpacity = viewAggregate && Math.min((1 / viewAggregate.positions.length) * 150, 0.5);

    return (
      <svg
        className={styles.overlay}
        style={{
          width: screenRect.width,
          height: screenRect.height,
        }}
      >
        {viewAggregate &&
          viewAggregate.positions.map((point, key) => (
            <circle
              key={point.key}
              cx={0}
              cy={0}
              r={24}
              data-direction={point.direction}
              className={styles.aggregatePoint}
              style={{
                transform: `translate(${point.x * screenRect.width}px, ${
                  point.y * screenRect.height
                }px)`,
                opacity: dotOpacity,
              }}
            />
          ))}
      </svg>
    );
  }
}

const selectViewAggregate = createSelector(
  selectAggregateData,
  selectAggregateScroll,
  selectCreativeFormat,
  (aggregateData, aggregateScroll, format) => {
    if (!aggregateData) {
      return null;
    }
    const offsetScroll = getCreativeOffset(format) - aggregateScroll;
    const step = aggregateData.find((d) => offsetScroll >= d.stepStart && offsetScroll < d.stepEnd);
    if (!step) {
      return null;
    }
    return step;
  }
);

const mapStateToProps = (state) => ({
  playing: selectPlaying(state),
  viewAggregate: selectViewAggregate(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HangtimeAggregateVis);
